import React from 'react';
import styles from '../../css/disclosure.module.css';

const Disclosure = () => {
    return (
        <>
            <div className={styles.disclosureContainer}>
                <p>
            This calculator is for illustrative purposes only. The results are
            based on your input, and are not intended to be a financial plan or
            advice from the Principal Financial Group®. This is not a life
            insurance policy or a complete statement of its provisions. The
            quote is an estimate only. Actual premium and rate class will vary
            based on a variety of factors. Eligibility, premiums, and rate class
            will be determined in the underwriting process. Your policy will
            provide costs and coverage details.
                    <br />
                    <br />
            Guarantees are based on the
            claims-paying ability of the issuing insurance company. All
            guarantees and benefits of the insurance policy are backed by the
            claims-paying ability of the issuing insurance company. Policy
            guarantees and benefits are not obligations of, nor backed by, the
            broker/dealer and/or insurance agency selling the policy, nor by any
            of their affiliates, and none of them makes any representations or
            guarantees regarding the claims-paying ability of the issuing
            insurance company.
                    <br />
                    <br />
            In exchange for the death benefit, life insurance
            products charge fees such as mortality and expense risk charges and
            surrender fees.
                    <br />
                    <br />
            Life insurance products issued by Principal National Life Insurance
            Company (except in NY) and Principal Life Insurance Company® , and
            the companies available through the Preferred Product Network, Inc.
            Plan administrative services offered by Principal Life. Referenced
            companies are members of the Principal Financial Group®, Des Moines,
            IA 50392.
                    <br />
                    <br />
            Principal National Life Insurance Company Policy Form: ICC17 SN
            104/SN 104 Principal Life Insurance Company Policy Form: SF 975 NY
            and SF 975 NY U
                </p>
            </div>
            <p>2652877-112022</p>
        </>
    );
};
export default Disclosure;
