import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageProgress from '../page-progress';

import calculator from '../../calculator';

import { INFO_PAGES_KEY } from '../../constants';

const NextButton = ({ state, actions }) => {
    const history = useHistory();
    const location = useLocation();

    let label = 'Skip';
    let className = 'pds-button-ghost';

    let goToNextPage = () => actions.nextPage(INFO_PAGES_KEY, history, location);

    const page = PageProgress.State.getCurrent(
        state[INFO_PAGES_KEY], location
    );

    const nextPage = PageProgress.State.getNext(
        state[INFO_PAGES_KEY], location
    );

    if (nextPage === null || nextPage === 'results') {
        label = 'Continue to results';
        className = 'pds-button-primary';
        goToNextPage = () => {
            actions.viewCalcResults();
            history.push('/life-calculator/calculator/results');
        };
    } else if ((page === 'replace' && calculator.isReplaceValid(state))
            || (page === 'cover' && calculator.isCoverValid(state))
            || page === 'fund') {
        label = 'Continue';
        className = 'pds-button-primary';
    }

    return (
        <button type="button"
            className={`pds-button ${className}`}
            onClick={() => {
                window.scrollTo(0, 0);
                goToNextPage();
            }}
        >
            {label}
        </button>
    );
};

NextButton.propTypes = {
    actions: PropTypes.shape({
        nextPage: PropTypes.func.isRequired,
        viewCalcResults: PropTypes.func.isRequired
    }).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};

export default {
    Component: NextButton
};
