import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import stateChecker from '../../state-checker';
import stateHelpers from '../../state-helpers';

import Label from '../label';
import NumberInput from '../number-input';
import ErrorMessage from '../error-message';

import styles from '../../css/date-entry.module.css';

const getDate = ({ day, month, year }) => {
    let dayValue = NumberInput.State.get(day);
    let monthValue = NumberInput.State.get(month);
    const yearValue = NumberInput.State.get(year);

    if (dayValue < 10) {
        dayValue = `0${dayValue}`;
    }

    if (monthValue < 10) {
        monthValue = `0${monthValue}`;
    }

    return (`${yearValue}-${monthValue}-${dayValue}`);
};

// This will run validation logic and update the state object with
// validation data.
const validate = (state) => {
    // We'll start by assuming we're _not_ in a valid state.
    state.isValid = false;

    if (!(NumberInput.State.isValid(state.day)
          && NumberInput.State.isValid(state.month)
          && NumberInput.State.isValid(state.year))) {
        // The message will get displayed by the normal rendering process.
        return state;
    }

    if (state.yearHasFocus && NumberInput.State.get(state.year) < 1900) {
        // They're still typing in the year. Don't show an error... yet.
        return state;
    }

    const dateStr = getDate(state);
    const date = moment(dateStr, 'YYYY-M-D', true);

    if (!date.isValid()) {
        state.hasError = true;
        switch (date.invalidAt()) {
        case 0:
            state.errorMsg = 'Provide a valid year';
            break;
        case 1:
            state.errorMsg = 'Provide a valid month';
            break;
        case 2:
            state.errorMsg = 'Provide a valid day';
            break;
        default:
            state.errorMsg = 'Provide a valid date';
        }
        return state;
    }

    const age = Math.round(moment().diff(date, 'years', true));
    if (age < 20) {
        state.hasError = true;
        state.errorMsg = 'We can only provide quotes to customers over 20.';
        return state;
    }

    if (age > 85) {
        state.hasError = true;
        state.errorMsg = 'We can only provide quotes to customers under 85.';
        return state;
    }

    state.isValid = true;
    state.hasError = false;
    state.errorMsg = null;
    return state;
};

const set = (state, year, month, day) => {
    state.day = NumberInput.State.set(state.day, day);
    state.month = NumberInput.State.set(state.month, month);
    state.year = NumberInput.State.set(state.year, year);

    return validate(state);
};

const setFromStr = (state, dateStr) => {
    const date = moment(dateStr, 'YYYY-M-D', true);

    if (!date.isValid()) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = 'Bad date string';
        return state;
    }

    state.day = NumberInput.State.set(state.day, date.date());
    state.month = NumberInput.State.set(state.month, date.month() + 1);
    state.year = NumberInput.State.set(state.year, date.year());

    return validate(state);
};

const getErrorState = state => {
    const day = NumberInput.State.getErrorState(state.day);
    if (day.hasError) {
        return {
            hasError: true,
            errorMsg: day.errorMsg
        };
    }

    const month = NumberInput.State.getErrorState(state.month);
    if (month.hasError) {
        return {
            hasError: true,
            errorMsg: month.errorMsg
        };
    }

    const year = NumberInput.State.getErrorState(state.year);
    if (year.hasError) {
        return {
            hasError: true,
            errorMsg: year.errorMsg
        };
    }

    if (state.hasError) {
        return {
            hasError: true,
            errorMsg: state.errorMsg
        };
    }

    return {
        hasError: false,
        errorMsg: null
    };
};

const DateEntryState = {
    Initial: () => ({
        ...NumberInput.State.Initial('day', {
            title: 'Day',
            min: 1,
            max: 31,
            allowNull: false
        }),
        ...NumberInput.State.Initial('month', {
            title: 'Month',
            min: 1,
            max: 12,
            allowNull: false
        }),
        ...NumberInput.State.Initial('year', {
            title: 'Year',
            min: 1,
            max: 2030,
            allowNull: false,
            allowThousandsSep: false
        }),
        isValid: true,
        hasError: false,
        errorMsg: null,
        yearHasFocus: false
    }),
    getDate,
    set,
    setFromStr,
    validate,
    isValid: ({ day, month, year, isValid }) => (
        NumberInput.State.isValid(day)
        && NumberInput.State.isValid(month)
        && NumberInput.State.isValid(year)
        && isValid
    ),
    Actions: (update) => ({
        validateDate: id => update({ [id]: state => validate(state) }),
        validateDateIfReady: id => update({
            [id]: state => {
                if (!(NumberInput.State.isValid(state.day)
                      && NumberInput.State.isValid(state.month)
                      && NumberInput.State.isValid(state.year))) {
                    return state;
                }

                return validate(state);
            }
        }),
        setYearFocused: (id, focused) => update({
            [id]: state => {
                state.yearHasFocus = focused;

                if (focused) { return state; }

                return validate(state);
            }
        }),
        dateActions: stateHelpers.mapActions(
            update, NumberInput.State.Actions
        )
    })
};


const DateEntryComponent = ({ id, state, actions }) => {
    const { hasError, errorMsg } = getErrorState(state[id]);

    return (
        <div className={styles.entry}>
            <Label.Component htmlFor="dob"
                label="Date of birth"
                required
            />
            <div className={styles.input} id="dob">
                <div className={styles.month}>
                    <label htmlFor="number-month"
                        className={styles.fieldDescription}
                    >
                        Month
                    </label>
                    <NumberInput.Component id="month"
                        state={state[id]}
                        actions={actions.dateActions(id)}
                        onChange={() => actions.validateDateIfReady(id)}
                        placeholder="MM"
                        required
                        dataCy="month"
                    />
                </div>
                <div className={styles.day}>
                    <label htmlFor="number-day"
                        className={styles.fieldDescription}
                    >
                        Day
                    </label>
                    <NumberInput.Component id="day"
                        state={state[id]}
                        actions={actions.dateActions(id)}
                        onChange={() => actions.validateDateIfReady(id)}
                        placeholder="DD"
                        required
                        dataCy="day"
                    />
                </div>
                <div className={styles.year}>
                    <label htmlFor="number-year"
                        className={styles.fieldDescription}
                    >
                        Year
                    </label>
                    <NumberInput.Component id="year"
                        state={state[id]}
                        actions={actions.dateActions(id)}
                        onChange={() => actions.validateDateIfReady(id)}
                        onFocusChange={
                            focused => actions.setYearFocused(id, focused)
                        }
                        placeholder="YYYY"
                        required
                        dataCy="year"
                    />
                </div>
            </div>
            <ErrorMessage hasError={hasError} errorMsg={errorMsg} />
        </div>
    );
};

DateEntryComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: PropTypes.shape({
        dateActions: PropTypes.func.isRequired,
        setYearFocused: PropTypes.func.isRequired
    }).isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        PropTypes.shape({
            day: NumberInput.statePropTypes,
            month: NumberInput.statePropTypes,
            year: NumberInput.statePropTypes
        }).isRequired
    )
    /* eslint-enable react/require-default-props */
};

export default {
    State: DateEntryState,
    Component: DateEntryComponent
};
