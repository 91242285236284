import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import shared from '@principal/life-calculator-helpers';

import PieChart from '../pie-chart';
import Slider from '../slider';
import SvgPie from '../pie-chart/pie-chart-svg';

import styles from '../../css/quote-result.module.css';
import CalcPieImage from '../../img/pie-calculator.png';

import {
    CALC_RESULTS_PIE,
    QUOTE_FACE_VALUE
} from '../../constants';


const defaultSlices = [
    { name: 'Replace income', color: '#0091DA', value: 45 },
    { name: 'Cover debt', color: '#00A29B', value: 25 },
    { name: 'Fund education', color: '#FF9231', value: 28 },
    { name: 'Final expenses', color: '#464E7E', value: 2 }
];


const CalcPie = ({ slices }) => (
    <div className={styles.pie}>
        <SvgPie.Chart id={CALC_RESULTS_PIE}
            slices={slices}
            size={60}
            img={<img src={CalcPieImage} alt="Calculator" />}
        />
    </div>
);

CalcPie.propTypes = {
    slices: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    })).isRequired
};


const Calculator = ({ state, actions }) => {
    const history = useHistory();

    const calced = PieChart.State.hasSlices(state[CALC_RESULTS_PIE]);

    const slices = calced ? state[CALC_RESULTS_PIE].slices : defaultSlices;

    const cta = (
        calced
            ? (
                <div className={styles.calc}>
                    <p className="pds-typography-small">
                        Your estimated need: $
                        {shared.formatNumber(
                            PieChart.State.getTotal(state[CALC_RESULTS_PIE])
                        )}
                    </p>
                    <a href="#"
                        onClick={evt => {
                            history.push('/life-calculator/calculator/results');
                            evt.preventDefault();
                        }}
                    >
                        Review your values
                    </a>
                </div>
            )
            : (
                <div className={styles.calc}>
                    <p className="pds-typography-small">
                        Not sure how much coverage you need?
                    </p>
                    <a href="#"
                        data-gtm="call-to-action-text"
                        onClick={evt => {
                            actions.backToQuoteResult();
                            history.push('/life-calculator/calculator');
                            evt.preventDefault();
                        }}
                    >
                        Use our calculator
                    </a>
                </div>
            )
    );

    return (
        <div className={styles.amountCallToAction}>
            <CalcPie slices={slices} />
            {cta}
        </div>
    );
};

Calculator.propTypes = {
    actions: PropTypes.shape(
        { backToQuoteResult: PropTypes.func.isRequired }
    ).isRequired,
    state: PropTypes.shape(
        { [CALC_RESULTS_PIE]: PieChart.statePropTypes }
    ).isRequired
};


const SelectAmount = ({ state, actions }) => (
    <div className={styles.cardItem}>
        <h2 className={styles.cardTitle}>Select your coverage amount</h2>
        <Slider.Component id={QUOTE_FACE_VALUE}
            state={state}
            actions={actions}
        />
        <Calculator state={state} actions={actions} />
    </div>
);


SelectAmount.propTypes = {
    actions: PropTypes.shape(
        { backToQuoteResult: PropTypes.func.isRequired }
    ).isRequired,
    state: PropTypes.shape({
        [CALC_RESULTS_PIE]: PieChart.statePropTypes,
        [QUOTE_FACE_VALUE]: Slider.statePropTypes
    }).isRequired
};


export default SelectAmount;
