import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import stateChecker from '../../state-checker';

import styles from '../../css/calc-options-select.module.css';

import CoverDebtIcon from '../../img/cover-debt-icon.png';
import FundEducationIcon from '../../img/fund-education-icon.png';
import ReplaceIncomeIcon from '../../img/replace-income-icon.png';

import PageProgress from '../page-progress';
import NumberInput from '../number-input';

import {
    FUND_NUMBER_KEY,
    INFO_PAGES_KEY
} from '../../constants';

const choiceSelected = (state, choice) => state.choices[choice] || false;

const infoPages = {
    replace: ['replace', 'Replace income', false, 'replace-income'],
    cover: ['cover', 'Cover debt', false, 'cover-debt'],
    fund: ['fund', 'Fund education', false, 'fund-education']
};

const setPages = (state, id, pages) => ({
    ...state,
    [id]: PageProgress.State.setPages(state[id], pages)
});

const CalcOptionsState = ({
    Initial: id => {
        const state = { choices: {} };

        if (id !== null && id !== undefined) {
            return { [id]: state };
        }

        return state;
    },
    Actions: (update) => (
        {
            selectCalcOption: (id, option, selected) => update(
                state => {
                    state[id].choices[option] = selected;

                    if (option === 'fund') {
                        state[FUND_NUMBER_KEY] = NumberInput.State.set(
                            state[FUND_NUMBER_KEY],
                            selected ? 1 : 0
                        );
                    }

                    return state;
                }
            ),
            setCalcPages: (id, history) => update(
                state => {
                    const pages = ['replace', 'cover', 'fund']
                        .filter(key => state[id].choices[key])
                        .map(key => infoPages[key]);

                    if (pages.length === 1) {
                        pages.push(
                            ['results', 'Get results', false, 'results']
                        );
                    }

                    state = setPages(state, INFO_PAGES_KEY, pages);

                    PageProgress.State.navigateToFirst(
                        state[INFO_PAGES_KEY], history
                    );

                    return state;
                }
            )
        }
    )
});


const Option = ({ option, icon, title, subtitle, selected, toggleAction }) => {
    if (selected !== true) {
        selected = false;
    }

    let className = styles.option;
    let checkboxClass = styles.checkbox;
    if (selected) {
        className = `${className} ${styles.optionSelected}`;
        checkboxClass = `${checkboxClass} ${styles.checkboxChecked}`;
    }

    return (
        <div role="checkbox"
            className={className}
            tabIndex="0"
            aria-checked={selected}
            data-cy={option}
            onKeyDown={evt => {
                if (evt.keyCode === 13) {
                    toggleAction(selected);
                }
            }}
            onMouseDown={evt => evt.preventDefault()}
            onClick={() => {
                toggleAction(selected);
                window.dataLayer.push({
                    event: 'e_toolInteraction',
                    eventType: 'recommendation',
                    tool: {
                        name: 'Life Insurance Calculator',
                        title,
                        value: !selected,
                        action: 'click'
                    }
                });
            }}
        >
            <div className={styles.icon}>
                <img src={icon} alt="" />
            </div>
            <div className={styles.body}>
                <label htmlFor={`calc-option-${option}`}
                    className={styles.title}
                >
                    {title}
                </label>
                <p className={styles.subtitle}>
                    {subtitle}
                </p>
            </div>
            <div className={styles.checkboxCol}>
                <div className={checkboxClass} />
            </div>
        </div>
    );
};

Option.propTypes = {
    option: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    toggleAction: PropTypes.func.isRequired
};


const optionSelected = state => (
    Object.values(state.choices).find(choice => choice)
);


const CalcOptionsComponent = ({ id, state, actions }) => {
    const history = useHistory();

    return (
        <main className={styles.selector}>
            <h1 className={styles.sectionTitle}>
                Life Insurance Calculator
            </h1>
            <div className={styles.sectionSubtitle}>
                Select all categories important to you.
            </div>
            <div className={styles.options}>
                <Option option="replace"
                    icon={ReplaceIncomeIcon}
                    title="Replace income"
                    subtitle="Salary and all other forms of income"
                    selected={choiceSelected(state[id], 'replace')}
                    toggleAction={selected => (
                        actions.selectCalcOption(id, 'replace', !selected)
                    )}
                />
                <Option option="cover"
                    icon={CoverDebtIcon}
                    title="Cover debt"
                    subtitle="Mortgage, student loans, etc."
                    selected={choiceSelected(state[id], 'cover')}
                    toggleAction={selected => (
                        actions.selectCalcOption(id, 'cover', !selected)
                    )}
                />
                <Option option="fund"
                    icon={FundEducationIcon}
                    title="Fund education"
                    subtitle="College tuition for children or other family members"
                    selected={choiceSelected(state[id], 'fund')}
                    toggleAction={selected => (
                        actions.selectCalcOption(id, 'fund', !selected)
                    )}
                />
            </div>
            <div>
                <div className={styles.nextButton}>
                    <button type="button"
                        className="pds-button pds-button-primary"
                        disabled={!optionSelected(state[id])}
                        onClick={() => {
                            actions.setCalcPages(id, history);
                            window.scrollTo(0, 0);
                        }}
                    >
                        Continue
                    </button>
                </div>
                <div className={styles.nextButton}>
                    <Link to="/life-calculator/calculator/get-help"
                        data-gtm="call-to-action-text"
                    >
                        None of the above
                        <span aria-hidden="true"> &gt; </span>
                    </Link>
                </div>
            </div>
        </main>
    );
};

CalcOptionsComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        PropTypes.shape(
            { choices: PropTypes.objectOf(PropTypes.bool).isRequired }
        ).isRequired
    )
};


export default {
    State: CalcOptionsState,
    Component: CalcOptionsComponent
};
