import shared from '@principal/life-calculator-helpers';
import DollarInput from './components/dollar-input';
import NumberInput from './components/number-input';
import PieChart from './components/pie-chart';
import Slider from './components/slider';

import QuoteResult from './components/quote-result';

import {
    CALC_RESULTS_PIE,
    COVER_MORTGAGE_KEY,
    COVER_OTHER_KEY,
    COVER_STUDENT_LOAN_KEY,
    FINAL_EXPENSES_BURIAL,
    FINAL_EXPENSES_FUNERAL,
    FUND_NUMBER_KEY,
    FUND_TYPE_COSTS,
    FUND_TYPE_COST_KEY,
    FUND_TYPE_KEY,
    REPLACE_INCOME_AMOUNT,
    REPLACE_INCOME_MULTIPLIER,
    REPLACE_INCOME_GROWTH_RATE,
    REPLACE_INCOME_YEARS
} from './constants';

/* Helpers to compute calculator total values */
const computeReplaceIncomeFromState = (state) => shared.computeReplaceIncome(
    DollarInput.State.get(state[REPLACE_INCOME_AMOUNT]),
    NumberInput.State.get(state[REPLACE_INCOME_GROWTH_RATE]),
    NumberInput.State.get(state[REPLACE_INCOME_YEARS])
);

const computeCoverDebtFromState = (state) => shared.computeCoverDebt(
    DollarInput.State.get(state[COVER_MORTGAGE_KEY]),
    DollarInput.State.get(state[COVER_STUDENT_LOAN_KEY]),
    DollarInput.State.get(state[COVER_OTHER_KEY])
);

const computeFundEducationFromState = (state) => shared.computeFundEducation(
    NumberInput.State.get(state[FUND_NUMBER_KEY]),
    FUND_TYPE_COSTS[state[FUND_TYPE_KEY]].years,
    Slider.State.getValue(state[FUND_TYPE_COST_KEY])
);

const computeFinalExpensesFromState = (state) => shared.computeFinalExpenses(
    DollarInput.State.get(state[FINAL_EXPENSES_BURIAL]),
    DollarInput.State.get(state[FINAL_EXPENSES_FUNERAL])
);

/* Validators for the calculator fields. */
const isReplaceValid = (state) => {
    const multiplier = state[REPLACE_INCOME_MULTIPLIER];
    return (
        DollarInput.State.isValid(state[REPLACE_INCOME_AMOUNT]) && (multiplier === 1 || multiplier === 12) && NumberInput.State.isValid(state[REPLACE_INCOME_YEARS])
    );
};

const isCoverValid = (state) => DollarInput.State.get(state[COVER_MORTGAGE_KEY]) > 0
  || DollarInput.State.get(state[COVER_STUDENT_LOAN_KEY]) > 0
  || DollarInput.State.get(state[COVER_OTHER_KEY]) > 0;

/* Set or update the Pie Chart Slices based on calculator inputs  */
const updateCalcResultsSlices = (state) => {
    const slices = [];

    const replaceAmount = computeReplaceIncomeFromState(state);
    if (isReplaceValid(state) && replaceAmount > 0) {
        slices.push({
            name: 'Replace income',
            color: '#0091DA',
            value: replaceAmount
        });
    }

    const coverAmount = computeCoverDebtFromState(state);
    if (isCoverValid(state) && coverAmount > 0) {
        slices.push({
            name: 'Cover debt',
            color: '#00A29B',
            value: coverAmount
        });
    }

    const fundAmount = computeFundEducationFromState(state);
    if (fundAmount > 0) {
        slices.push({
            name: 'Fund education',
            color: '#FF9231',
            value: fundAmount
        });
    }

    const finalExpenses = computeFinalExpensesFromState(state);
    if (finalExpenses > 0) {
        slices.push({
            name: 'Final expenses',
            color: '#464E7E',
            value: finalExpenses
        });
    }

    // Update the pie chart with the new slices.
    state[CALC_RESULTS_PIE] = PieChart.State.setSlices(
        state[CALC_RESULTS_PIE],
        slices
    );

    // Update the Quote Results slider with the calculated needs value.
    return QuoteResult.State.insertCalculatorValue(
        state,
        state[CALC_RESULTS_PIE].totalValue
    );
};

export default {
    computeReplaceIncomeFromState,
    computeCoverDebtFromState,
    computeFundEducationFromState,
    computeFinalExpensesFromState,
    updateCalcResultsSlices,
    isReplaceValid,
    isCoverValid
};
