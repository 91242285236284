@value pdsDarkGray, pdsMediumLightGray, regularFont from './common.module.css';
@value tinyScreen, smallScreen, largeScreen, bigScreen, hugeScreen from './common.module.css';


.callsToAction {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    margin-top: 35px;
    min-width: 260px;
    max-width: 490px;
    width: 100%;
}

@media all and (min-width: largeScreen) {
    .callsToAction {
        flex-direction: row;
        justify-content: space-around;

        width: 740px;
        max-width: none;

        padding: 20px 5px;

        border: 1px solid pdsMediumLightGray;
        border-radius: 2px;
    }
}

@media all and (min-width: hugeScreen) {
    .callsToAction {
        width: 1032px;
    }
}

.compact {
    display: flex;
    flex-direction: column;

    margin-top: 0;
    width: 100%;
    border: 0;
}

@media all and (min-width: bigScreen) {
    .compact {
        margin-top: 35px;
        flex-direction: row;
        padding: 0;
    }
}


.actionCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 30px 15px;

    min-height: 297px;
    flex-basis: 33%;
}

.compact .actionCard {
    min-height: auto;
    width: 100%;

    flex-wrap: wrap;
    justify-content: space-evenly;

    border: 0;
    padding: 10px 0 10px 0;
    margin: 0;
}

.compact .advisor {
    height: 180px;
}

.compact .actionTitle {
    order: 2;
    flex: 0 1 auto;
    width: 40%;
    font-size: 16px;
}

.compact .actionAction {
    order: 3;
    flex: 0 1 50%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media all and (max-width: smallScreen) {
    .compact .actionTitle {
        font-size: 14px;
    }

    .compact button {
        font-size: 14px;
        padding: 9px 25px;
    }
}

@media all and (min-width: bigScreen) {
    .actionCard {
        border: 0;
        margin: 0;
        flex-basis: 33%;
    }
}

@media all and (min-width: bigScreen) {
    .compact .actionTitle {
        flex: 0 0 auto;
        order: 1;
        font-size: 20px;
        line-height: 28px;
        width: auto;
        margin-bottom: 30px;
    }

    .compact .actionAction {
        flex: 0 0 auto;
        order: 3;
        width: auto;
    }

    .compact .actionCard {
        width: auto;
        min-width: none;
        max-width: none;
        flex-direction: column;
        justify-content: initial;
        flex-grow: 1;
        flex-basis: 33%;
        padding: 0;
        border: 0 !important;
    }
}


.actionTitle {
    color: pdsDarkGray;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}


.callUsActions {
    display: flex;
    flex-direction: column;

    align-items: center;
    text-align: center;
    margin-top: 10px;
    width: 250px;
}

.callUsActions > a {
    font-size: 23px;
    line-height: 28px;
    max-width: 150px;
}

.callUsActions > div {
    color: pdsDarkGray;
    margin-top: 10px;
    font-size: 16px;
    line-height: 23px;
}

.compact .callUsActions > div {
    font-size: 13px;
    line-height: 18px;
}

/* only largeScreen */
@media all and (min-width: largeScreen) {
    .callUsActions > div {
        font-size: 14px;
    }
}

@media all and (min-width: bigScreen) {
    .compact .callUsActions > div {
        font-size: 16px;
        line-height: normal;
    }
}

@media all and (min-width: hugeScreen) {
    .callUsActions > div {
        font-size: 16px;
    }
}
