"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.computeReplaceIncome = exports.computeFundEducation = exports.computeFinalExpenses = exports.computeCoverDebt = void 0;
/* Helpers to compute calculator total values */
var computeReplaceIncome = function computeReplaceIncome(baseIncome, growthRate, years) {
  var annualGrowthRate = 1 + growthRate / 100;
  var income = 0;
  for (var year = 0; year < years; year += 1) {
    income += Math.round(baseIncome * Math.pow(annualGrowthRate, year));
  }
  return income;
};
exports.computeReplaceIncome = computeReplaceIncome;
var computeCoverDebt = function computeCoverDebt(mortgage, studentLoans, other) {
  return mortgage + studentLoans + other;
};
exports.computeCoverDebt = computeCoverDebt;
var computeFundEducation = function computeFundEducation(attendees, years, annualCost) {
  return attendees * years * annualCost;
};
exports.computeFundEducation = computeFundEducation;
var computeFinalExpenses = function computeFinalExpenses(burial, funeral) {
  return burial + funeral;
};
exports.computeFinalExpenses = computeFinalExpenses;
var _default = {
  computeReplaceIncome: computeReplaceIncome,
  computeCoverDebt: computeCoverDebt,
  computeFundEducation: computeFundEducation,
  computeFinalExpenses: computeFinalExpenses
};
exports["default"] = _default;