import React from 'react';
import PropTypes from 'prop-types';
import shared from '@principal/life-calculator-helpers';

import Slider from '../slider';
import Tooltip from '../tooltip';

import styles from '../../css/quote-result.module.css';

import {
    QUOTE_FACE_VALUE,
    QUOTE_TERM_RATES
} from '../../constants';


const TERMS = [10, 20, 30];


const TermCard = ({ faceValue, term, rate }) => {
    const premium = shared.formatNumber(
        shared.computePremium(faceValue, rate)
    );

    return (
        <div className={styles.premiumQuote}>
            <p className="pds-typography-small">Term</p>
            <p className="pds-typography-regular">{ `${term} Year` }</p>
            <p className={
                [styles.premiumLabel, 'pds-typography-small'].join(' ')
            }
            >
                Premium
            </p>
            <p className={styles.premiumAmount}>
                <span>{`$${premium}`}</span>
                /mo
            </p>
        </div>
    );
};

TermCard.propTypes = {
    faceValue: PropTypes.number.isRequired,
    term: PropTypes.number.isRequired,
    rate: PropTypes.number.isRequired
};


const getTermRate = (state, term, faceValue) => (
    parseFloat(state[QUOTE_TERM_RATES][shared.buildTermKey(faceValue, term)])
);

const getTermValues = (state, faceValue) => {
    return TERMS.map(term => {
        const rate = getTermRate(state, term, faceValue);

        if (!Number.isFinite(rate)) {
            return null;
        }
        return (
            <TermCard key={term}
                faceValue={faceValue}
                term={term}
                rate={rate}
            />
        );
    });
};

const howToChooseTermHeader = () => (
    <span>How to choose term length</span>
);

const howToChooseTermBody = () => (
    <span>
        One way to choose a term is to think about how long your loved ones
        will rely on the benefits it provides after you’re gone. That’s because
        term life insurance can help cover costs that diminish over time—like debts,
        mortgage balances, and education expenses. Your dependents receive a tax-free
        benefit to help cover costs if you die during the policy term. And applying
        for coverage when you’re younger can help you take advantage of lower age-based
        rates.
    </span>
);
const CostForCoverage = ({ state, actions }) => {
    const faceValue = Slider.State.getValue(state[QUOTE_FACE_VALUE]);
    const values = getTermValues(state, faceValue).filter(Boolean);
    return (
        <div className={styles.cardItem}>
            <h2 className={styles.cardTitle}>Cost for coverage</h2>
            <div className={styles.cardSubtitle}>
                <span>Term life insurance</span>
                <Tooltip.Component position="top"
                    tip="Get coverage for the selected term"
                />
            </div>
            <div className={styles.quotes}>
                {values}
            </div>
            {
                (values.length === 1) ? null
                    : (
                        <button type="button"
                            data-gtm="call-to-action-text"
                            className={styles.howToChooseTerm}
                            onClick={() => {
                                window.scrollTo(0, 0);
                                actions.showModalContent(
                                    'modal',
                                    howToChooseTermHeader,
                                    howToChooseTermBody
                                );
                            }}
                        >
                            How to choose a term length
                        </button>
                    )
            }
        </div>
    );
};
CostForCoverage.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.shape({
        [QUOTE_FACE_VALUE]: Slider.statePropTypes,
        [QUOTE_TERM_RATES]: PropTypes.objectOf(PropTypes.string).isRequired
    }).isRequired
};

export default CostForCoverage;
