/* eslint-disable no-return-assign */
import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
    Link
} from 'react-router-dom';

import PropTypes from 'prop-types';

import styles from '../../css/app.module.css';

import Quote from '../quote';
import Calculator from '../calculator';
import NavControls from '../nav';

import CalculatorIcon from '../../img/calculator.png';
import QuoteIcon from '../../img/quote.png';

import ScrollToTop from './scroll-to-top';

import Modal from '../modal';
import ContactForm from '../contact-form';

const AppState = ({
    Initial: () => ({
        ...Calculator.State.Initial(),
        ...Quote.State.Initial(),
        ...NavControls.State.Initial('nav'),
        ...Modal.State.Initial('modal'),
        ...ContactForm.State.Initial('schedule')
    }),
    Actions: (update) => (
        {
            ...Calculator.State.Actions(update),
            ...Quote.State.Actions(update),
            ...NavControls.State.Actions(update),
            ...Modal.State.Actions(update),
            ...ContactForm.State.Actions(update)
        }
    )
});


const SelectFlow = () => (
    <main className={styles.selector}>
        <h1 className={styles.sectionTitle}>
            Find out how much life insurance you need or get a rate quote.
        </h1>
        <div className={styles.options}>
            <Link to="/life-calculator/calculator">
                <div className={styles.option}>
                    <div className={styles.icon}>
                        <img src={CalculatorIcon} alt="" />
                    </div>
                    <div className={styles.body}>
                        <div className={styles.title}>
                            Needs calculator
                        </div>
                        <div className={styles.description}>
                            Answer a few questions to determine how much life
                            insurance you need.
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/life-calculator/quote/about">
                <div className={styles.option}>
                    <div className={styles.icon}>
                        <img src={QuoteIcon} alt="" />
                    </div>
                    <div className={styles.body}>
                        <div className={styles.title}>
                            Rate quote
                        </div>
                        <div className={styles.description}>
                            Already know how much you need? Get a quote for a
                            policy.
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    </main>
);


const BASE_CRUMBS = [
    { name: 'Life insurance for business owners', url: 'https://www.principal.com/businesses' }
];


const DisplayFlow = ({ state, actions }) => (
    <div className={styles.appWrapper}>
        <Switch>
            {/* <Route exact path="/" render={() => (window.location = 'https://www.principal.com/individuals/insure/life-insurance/protect-what-matters-most')} /> */}

            <Route path="/life-calculator/calculator">
                <NavControls.Component id="nav"
                    state={state}
                    actions={actions}
                    crumbs={BASE_CRUMBS}
                    lastCrumb="Life insurance calculator"
                />
                <div className={styles.appContainer}>
                    <Calculator.Component id="calculator"
                        state={state}
                        actions={actions}
                    />
                </div>
            </Route>
            <Route path="/life-calculator/quote">
                <NavControls.Component id="nav"
                    state={state}
                    actions={actions}
                    crumbs={BASE_CRUMBS}
                    lastCrumb="Life insurance quote"
                />
                <div className={styles.appContainer}>
                    <Quote.Component id="quote"
                        state={state}
                        actions={actions}
                    />
                </div>
            </Route>
            <Route exact path="/life-calculator">
                <NavControls.Component id="nav"
                    state={state}
                    actions={actions}
                    crumbs={BASE_CRUMBS}
                    lastCrumb="Calculator &amp; Quote"
                />
                <div className={styles.appContainer}>
                    <SelectFlow />
                </div>
            </Route>
        </Switch>
    </div>
);


DisplayFlow.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};


class AppComponent extends React.Component {

    constructor(props) {
        super(props);
        this.skippedFirst = false;

        // Our initial state is the most recent state from the stream.
        this.state = props.states();
    }

    componentDidMount() {
        // Set a stream listener that calls setState on this component any time
        // the model state changes.
        const setState = this.setState.bind(this);
        const { states } = this.props; // This is for eslint...
        states.map(state => setState(state));
    }

    render() {
        const state = this.state;
        const { actions } = this.props;
        return (
            <>
                <BrowserRouter>
                    <ScrollToTop />
                    <DisplayFlow state={state} actions={actions} />
                </BrowserRouter>
                <Modal.Component id="modal" state={state} actions={actions} />
            </>
        );
    }

}


AppComponent.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    states: PropTypes.func.isRequired
};
// state: PropTypes.any.isRequired,


export default {
    State: AppState,
    Component: AppComponent
};
