"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.computePremium = exports.buildTermKey = void 0;
var _constants = require("./constants");
var buildTermKey = function buildTermKey(faceValue, term) {
  var value = '';
  if (faceValue < 1000000) {
    value += 'A';
  } else {
    value += 'B';
  }
  if (term === 10) {
    value += '1';
  } else if (term === 20) {
    value += '2';
  } else {
    value += '3';
  }
  return value;
};
exports.buildTermKey = buildTermKey;
var computePremium = function computePremium(faceValue, rate) {
  var scaledFaceValue = faceValue / 1000 * parseFloat(rate, 10);
  return Math.round((scaledFaceValue + _constants.SERVICE_FEE) * _constants.EFT_MULTIPLE);
};
exports.computePremium = computePremium;
var _default = {
  buildTermKey: buildTermKey,
  computePremium: computePremium
};
exports["default"] = _default;