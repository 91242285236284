import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import stateChecker from '../../state-checker';

import Breadcrumb from '../breadcrumb';

import styles from '../../css/nav.module.css';

import { PAGE_MAP } from '../../constants';

const resetState = () => ({
    label: 'Back',
    action: () => (history => history.goBack())
});

const NavState = ({
    Initial: id => {
        const state = {
            label: 'Back',
            action: history => history.goBack()
        };

        if (id) {
            return { [id]: state };
        }

        return state;
    },
    setLabel: (state, label) => ({ ...state, label }),
    setAction: (state, action) => ({ ...state, action }),
    setLabelAction: (state, label, action) => ({
        ...state,
        label,
        action
    }),
    resetState: state => resetState(state),
    Actions: update => ({
        setNavLabel: (id, label) => update({ [id]: { label } }),
        setNavAction: (id, action) => update(
            { [id]: { action: () => action } }
        ),
        resetNav: id => update({ [id]: resetState({}) })
    })
});


const NavControls = ({ id, state, crumbs, lastCrumb }) => {
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        if (PAGE_MAP[pathname]) {
            window.dataLayer.push({
                event: 'routeChange',
                pagePath: pathname,
                pageTitle: PAGE_MAP[pathname]
            });
        } else {
            window.dataLayer.push({
                event: 'routeChange',
                pagePath: pathname,
                pageTitle: 'Unsupported Page'
            });
        }
    },
    [pathname]);

    return (
        <nav className={styles.nav} aria-label="Primary Navigation">
            <Breadcrumb.Component crumbs={
                crumbs.concat({ name: lastCrumb })
            }
            />
            <button className={styles.back}
                type="button"
                onClick={() => {
                    state[id].action(history);
                }}
                onKeyPress={() => {
                    state[id].action(history);
                }}
            >
                {state[id].label}
            </button>
        </nav>
    );
};

NavControls.propTypes = {
    lastCrumb: PropTypes.string.isRequired,
    crumbs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    })).isRequired,
    id: PropTypes.string.isRequired,
    state: stateChecker(PropTypes.shape({ // eslint-disable-line react/require-default-props, max-len
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired
    }))
};


export default {
    Component: NavControls,
    State: NavState
};
