"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.SERVICE_FEE = exports.REPLACE_INCOME_YEARS = exports.REPLACE_INCOME_PAGE = exports.REPLACE_INCOME_MULTIPLIER = exports.REPLACE_INCOME_GROWTH_RATE = exports.REPLACE_INCOME_AMOUNT = exports.QUOTE_TERM_RATES = exports.QUOTE_RESULT_PAGE = exports.QUOTE_PAGE = exports.QUOTE_FACE_VALUE = exports.INFO_PAGES_KEY = exports.INFO_PAGES = exports.FUND_TYPE_YEARS = exports.FUND_TYPE_KEY = exports.FUND_TYPE_COST_KEY = exports.FUND_TYPE_COSTS = exports.FUND_NUMBER_KEY = exports.FUND_EDUCATION_PAGE = exports.FINAL_EXPENSES_FUNERAL = exports.FINAL_EXPENSES_BURIAL = exports.EFT_MULTIPLE = exports.COVER_STUDENT_LOAN_KEY = exports.COVER_OTHER_KEY = exports.COVER_MORTGAGE_KEY = exports.COVER_DEBT_PAGE = exports.CONTACT_ZIP_CODE = exports.CONTACT_PHONE = exports.CONTACT_NAME = exports.CONTACT_HOUR_KEY = exports.CONTACT_EMAIL = exports.CONTACT_APPOINTMENT = exports.ABOUT_WEIGHT = exports.ABOUT_TOBACCO = exports.ABOUT_STATE = exports.ABOUT_HEIGHT = exports.ABOUT_GENDER = exports.ABOUT_DOB = void 0;
// TERM Related constants.
// The EFT_MULTIPLE is used to scale term payments to their montly rate.

var EFT_MULTIPLE = 0.086;
exports.EFT_MULTIPLE = EFT_MULTIPLE;
var SERVICE_FEE = 75;

// Education Costs
/*
 * Public 4 year in-state: $9,037
 * Public 4 year out-of-state: $25,657
 * Private 4 year: $30,731
 * Public 2 year: $3,243
 * Try $500 increments rather than $1,000.
 * */
exports.SERVICE_FEE = SERVICE_FEE;
var FUND_TYPE_COSTS = {
  'public-in-state': {
    label: 'Public 4-year university (in-state)',
    average: 9037,
    max: 27000,
    increments: 55,
    years: 4
  },
  'public-out-of-state': {
    label: 'Public 4-year university (out-of-state)',
    average: 25657,
    max: 77000,
    increments: 155,
    years: 4
  },
  'private-university': {
    label: 'Private 4-year university',
    average: 30731,
    max: 92000,
    increments: 185,
    years: 4
  },
  'public-two-year': {
    label: 'Public 2-year college',
    average: 3243,
    max: 10000,
    increments: 21,
    years: 2
  }
};

// Calc Value Keys
exports.FUND_TYPE_COSTS = FUND_TYPE_COSTS;
var INFO_PAGES = 'calc:info';
exports.INFO_PAGES = INFO_PAGES;
var INFO_PAGES_KEY = 'calc:info:pages';
exports.INFO_PAGES_KEY = INFO_PAGES_KEY;
var REPLACE_INCOME_PAGE = 'calc:info:replace';
exports.REPLACE_INCOME_PAGE = REPLACE_INCOME_PAGE;
var REPLACE_INCOME_AMOUNT = 'calc:info:replace:income';
exports.REPLACE_INCOME_AMOUNT = REPLACE_INCOME_AMOUNT;
var REPLACE_INCOME_MULTIPLIER = 'calc:info:replace:multiplier';
exports.REPLACE_INCOME_MULTIPLIER = REPLACE_INCOME_MULTIPLIER;
var REPLACE_INCOME_YEARS = 'calc:info:replace:years';
exports.REPLACE_INCOME_YEARS = REPLACE_INCOME_YEARS;
var REPLACE_INCOME_GROWTH_RATE = 'calc:info:replace:growth';
exports.REPLACE_INCOME_GROWTH_RATE = REPLACE_INCOME_GROWTH_RATE;
var COVER_DEBT_PAGE = 'calc:info:cover';
exports.COVER_DEBT_PAGE = COVER_DEBT_PAGE;
var COVER_MORTGAGE_KEY = 'calc:info:cover:mortgage';
exports.COVER_MORTGAGE_KEY = COVER_MORTGAGE_KEY;
var COVER_STUDENT_LOAN_KEY = 'calc:info:cover:studentLoans';
exports.COVER_STUDENT_LOAN_KEY = COVER_STUDENT_LOAN_KEY;
var COVER_OTHER_KEY = 'calc:info:cover:other';
exports.COVER_OTHER_KEY = COVER_OTHER_KEY;
var FUND_EDUCATION_PAGE = 'calc:info:fund';
exports.FUND_EDUCATION_PAGE = FUND_EDUCATION_PAGE;
var FUND_NUMBER_KEY = 'calc:info:fund:number';
exports.FUND_NUMBER_KEY = FUND_NUMBER_KEY;
var FUND_TYPE_KEY = 'calc:info:fund:type';
exports.FUND_TYPE_KEY = FUND_TYPE_KEY;
var FUND_TYPE_COST_KEY = 'calc:info:fund:typeCost';
exports.FUND_TYPE_COST_KEY = FUND_TYPE_COST_KEY;
var FUND_TYPE_YEARS = 'calc:info:fund:typeYears';
exports.FUND_TYPE_YEARS = FUND_TYPE_YEARS;
var FINAL_EXPENSES_BURIAL = 'calc:info:final:burial';
exports.FINAL_EXPENSES_BURIAL = FINAL_EXPENSES_BURIAL;
var FINAL_EXPENSES_FUNERAL = 'calc:info:final:funeral';
exports.FINAL_EXPENSES_FUNERAL = FINAL_EXPENSES_FUNERAL;
var ABOUT_DOB = 'quote:about:dob';
exports.ABOUT_DOB = ABOUT_DOB;
var ABOUT_GENDER = 'quote:about:gender';
exports.ABOUT_GENDER = ABOUT_GENDER;
var ABOUT_STATE = 'quote:about:state';
exports.ABOUT_STATE = ABOUT_STATE;
var ABOUT_HEIGHT = 'quote:about:height';
exports.ABOUT_HEIGHT = ABOUT_HEIGHT;
var ABOUT_WEIGHT = 'quote:about:weight';
exports.ABOUT_WEIGHT = ABOUT_WEIGHT;
var ABOUT_TOBACCO = 'quote:about:tobacco';
exports.ABOUT_TOBACCO = ABOUT_TOBACCO;
var QUOTE_PAGE = 'quote:page';
exports.QUOTE_PAGE = QUOTE_PAGE;
var QUOTE_RESULT_PAGE = 'quote:result';
exports.QUOTE_RESULT_PAGE = QUOTE_RESULT_PAGE;
var QUOTE_FACE_VALUE = 'quote:result:faceValue';
exports.QUOTE_FACE_VALUE = QUOTE_FACE_VALUE;
var QUOTE_TERM_RATES = 'quote:termRates';
exports.QUOTE_TERM_RATES = QUOTE_TERM_RATES;
var CONTACT_NAME = 'contact:name';
exports.CONTACT_NAME = CONTACT_NAME;
var CONTACT_EMAIL = 'contact:email';
exports.CONTACT_EMAIL = CONTACT_EMAIL;
var CONTACT_PHONE = 'contact:phone';
exports.CONTACT_PHONE = CONTACT_PHONE;
var CONTACT_ZIP_CODE = 'contact:zip';
exports.CONTACT_ZIP_CODE = CONTACT_ZIP_CODE;
var CONTACT_APPOINTMENT = 'contact:appointment';
exports.CONTACT_APPOINTMENT = CONTACT_APPOINTMENT;
var CONTACT_HOUR_KEY = 'contact:appointment:hour';
exports.CONTACT_HOUR_KEY = CONTACT_HOUR_KEY;
var _default = {
  EFT_MULTIPLE: EFT_MULTIPLE,
  SERVICE_FEE: SERVICE_FEE,
  ABOUT_DOB: ABOUT_DOB,
  ABOUT_GENDER: ABOUT_GENDER,
  ABOUT_HEIGHT: ABOUT_HEIGHT,
  ABOUT_TOBACCO: ABOUT_TOBACCO,
  ABOUT_WEIGHT: ABOUT_WEIGHT,
  ABOUT_STATE: ABOUT_STATE,
  COVER_DEBT_PAGE: COVER_DEBT_PAGE,
  COVER_MORTGAGE_KEY: COVER_MORTGAGE_KEY,
  COVER_OTHER_KEY: COVER_OTHER_KEY,
  COVER_STUDENT_LOAN_KEY: COVER_STUDENT_LOAN_KEY,
  FINAL_EXPENSES_BURIAL: FINAL_EXPENSES_BURIAL,
  FINAL_EXPENSES_FUNERAL: FINAL_EXPENSES_FUNERAL,
  FUND_EDUCATION_PAGE: FUND_EDUCATION_PAGE,
  FUND_NUMBER_KEY: FUND_NUMBER_KEY,
  FUND_TYPE_COSTS: FUND_TYPE_COSTS,
  FUND_TYPE_COST_KEY: FUND_TYPE_COST_KEY,
  FUND_TYPE_KEY: FUND_TYPE_KEY,
  FUND_TYPE_YEARS: FUND_TYPE_YEARS,
  INFO_PAGES: INFO_PAGES,
  INFO_PAGES_KEY: INFO_PAGES_KEY,
  QUOTE_FACE_VALUE: QUOTE_FACE_VALUE,
  QUOTE_TERM_RATES: QUOTE_TERM_RATES,
  QUOTE_PAGE: QUOTE_PAGE,
  QUOTE_RESULT_PAGE: QUOTE_RESULT_PAGE,
  REPLACE_INCOME_AMOUNT: REPLACE_INCOME_AMOUNT,
  REPLACE_INCOME_GROWTH_RATE: REPLACE_INCOME_GROWTH_RATE,
  REPLACE_INCOME_MULTIPLIER: REPLACE_INCOME_MULTIPLIER,
  REPLACE_INCOME_PAGE: REPLACE_INCOME_PAGE,
  REPLACE_INCOME_YEARS: REPLACE_INCOME_YEARS,
  CONTACT_NAME: CONTACT_NAME,
  CONTACT_EMAIL: CONTACT_EMAIL,
  CONTACT_PHONE: CONTACT_PHONE,
  CONTACT_ZIP_CODE: CONTACT_ZIP_CODE,
  CONTACT_APPOINTMENT: CONTACT_APPOINTMENT,
  CONTACT_HOUR_KEY: CONTACT_HOUR_KEY
};
exports["default"] = _default;